<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
      top
      right
      absolute
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <v-card v-if="userRole === 'admin'" elevation="12" min-height="600">
          <v-card-title>Avvisi</v-card-title>
          <v-card-text
            >Il sistema invierà una comunicazione ai canali Telegram e Twitter
            del Dipartimento</v-card-text
          >
          <v-row v-show="!loading">
            <v-col cols="6">
              <v-card>
                <v-card-title>Testo della comunicazione</v-card-title>
                <v-card-text>
                  <v-textarea
                    :disabled="freeze === true"
                    v-model="newMessage"
                    label="Testo del messaggio"
                    :counter="279"
                    :rules="validationRules"
                    required
                    rows="5"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    :disabled="
                      newMessage.length === 0 ||
                      newMessage.length > 279 ||
                      freeze === true
                    "
                    @click="
                      freeze = true;
                      clearance = true;
                    "
                    >MEMORIZZA</v-btn
                  >
                  <v-spacer> </v-spacer>
                  <v-btn
                    @click="
                      newMessage = '';
                      freeze = false;
                      clearance = false;
                    "
                    >CANCELLA</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="primary"
                @click="sendTelegram(newMessage)"
                :disabled="freeze === false && clearance === false"
                >Invia con Telegram</v-btn
              >
              <br />
              <br />
              <v-btn
                color="primary"
                @click="sendTwitter(newMessage)"
                :disabled="freeze === false && clearance === false"
                >Invia con Twitter</v-btn
              >
              <br />
              <br />
              <v-btn color="primary" @click="listTwitter"
                >Recupera Messaggi Twitter</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="messageList"
              :items-per-page="10"
              class="elevation-1"
            >
            </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-else>
          <v-card-text>
            <h2>Accesso non consentito</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const moment = require("moment-timezone");
import { mapState } from "vuex";

export default {
  name: "TelegramTwitter",

  data: () => ({
    freeze: false,
    clearance: false,
    loading: false,
    permSnackbar: false,
    snackbarColor: "success",
    timeout: "1500",
    errorText: "",
    newMessage: "",
    validationRules: [
      (v) => !!v || "Il testo del messaggio è obbligatorio",
      (v) =>
        (v && v.length <= 279) ||
        "Il testo del messaggio deve essere lungo al massimo 279 caratteri",
    ],
    messageList: [],
    headers: [
      {
        text: "Data",
        align: "start",
        sortable: true,
        value: "created_at",
      },
      {
        text: "Messaggio Twitter",
        align: "left",
        sortable: false,
        value: "text",
      },
    ],
  }),
  computed: {
    ...mapState(["userInitials", "userRole", "labelMappingUser"]),
  },
  methods: {
    sendTelegram(message) {
      if (!message) {
        this.errorText = "Messaggio vuoto, operazione non permessa";
        this.permSnackbar = true;
        this.snackbarColor = "red";
      }

      this.loading = true;
      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API + "/texting/sendtelegram",
          { message: message },
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.errorText = response.data.message;
          this.snackbarColor = "success";
          this.permSnackbar = true;
          this.loading = false;
        })
        .catch((err) => {
          this.errorText = err.response.data.message;
          this.permSnackbar = true;
          this.snackbarColor = "red";

          this.loading = false;
        });
    },

    sendTwitter(message) {
      if (!message) {
        this.errorText = "Messaggio vuoto, operazione non permessa";
        this.permSnackbar = true;
        this.snackbarColor = "red";
      }

      this.loading = true;
      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API + "/texting/sendtwitter",
          { message: message },
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.errorText = response.data.message;
          this.snackbarColor = "success";
          this.permSnackbar = true;
          this.loading = false;
        })
        .catch((err) => {
          this.errorText = err.response.data.message;
          this.permSnackbar = true;
          this.snackbarColor = "red";
        });

      this.loading = false;
    },

    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("DD/MM/YYYY, HH:mm");
    },

    listTwitter() {
      this.loading = true;
      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API + "/texting/listtwitter",
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          //console.log(response.data.messageList);
          for (let i = 0; i < response.data.messageList.length; i++) {
            response.data.messageList[i].created_at = this.formatDate(
              response.data.messageList[i].created_at
            );
          }
          this.messageList = response.data.messageList;

          this.errorText = "Lista aggiornata";
          this.snackbarColor = "success";
          this.permSnackbar = true;
          this.loading = false;
        })
        .catch((err) => {
          this.errorText = err.response.data.message;
          this.permSnackbar = true;
          this.snackbarColor = "red";
        });

      this.loading = false;
    },
  },
};
</script>
