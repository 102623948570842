var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.timeout,"top":"","right":"","absolute":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){_vm.permSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.permSnackbar),callback:function ($$v) {_vm.permSnackbar=$$v},expression:"permSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.userRole === 'admin')?_c('v-card',{attrs:{"elevation":"12","min-height":"600"}},[_c('v-card-title',[_vm._v("Avvisi")]),_c('v-card-text',[_vm._v("Il sistema invierà una comunicazione ai canali Telegram e Twitter del Dipartimento")]),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("Testo della comunicazione")]),_c('v-card-text',[_c('v-textarea',{attrs:{"disabled":_vm.freeze === true,"label":"Testo del messaggio","counter":279,"rules":_vm.validationRules,"required":"","rows":"5"},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.newMessage.length === 0 ||
                    _vm.newMessage.length > 279 ||
                    _vm.freeze === true},on:{"click":function($event){_vm.freeze = true;
                    _vm.clearance = true;}}},[_vm._v("MEMORIZZA")]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.newMessage = '';
                    _vm.freeze = false;
                    _vm.clearance = false;}}},[_vm._v("CANCELLA")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.freeze === false && _vm.clearance === false},on:{"click":function($event){return _vm.sendTelegram(_vm.newMessage)}}},[_vm._v("Invia con Telegram")]),_c('br'),_c('br'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.freeze === false && _vm.clearance === false},on:{"click":function($event){return _vm.sendTwitter(_vm.newMessage)}}},[_vm._v("Invia con Twitter")]),_c('br'),_c('br'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.listTwitter}},[_vm._v("Recupera Messaggi Twitter")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.messageList,"items-per-page":10}})],1)],1)],1):_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v("Accesso non consentito")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }